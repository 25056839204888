import { ReactNode, useState } from 'react';
import { Card, Input, Button, CardProps, Box, Text, Link, Notification } from '@bitrise/bitkit';
import useMonolithApiMutation from '@/hooks/api/useMonolithApiMutation';
import TermsOfServiceAndPrivacyPolicy from '@/pages/SignUp/components/TermsOfServiceAndPrivacyPolicy/TermsOfServiceAndPrivacyPolicy';

const SSOEmailForm = ({
  onBack,
  header,
  cardProps,
  requireConsent,
  backLabel,
}: {
  onBack: () => void;
  header: ReactNode;
  cardProps: CardProps;
  requireConsent?: boolean;
  backLabel: string;
}) => {
  const [state, setState] = useState<'email' | 'sent' | 'resent'>('email');
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { mutate, reset, isPending } = useMonolithApiMutation('/users/sign-in/saml', 'post', {
    onSuccess(data) {
      const url = data.headers.location;
      if (typeof url === 'string') {
        window.location.assign(url);
      } else {
        setState(state === 'sent' ? 'resent' : 'sent');
      }
    },
    onSettled() {
      reset();
    },
  });
  const needsConsent = requireConsent && !consent;
  return state === 'email' ? (
    <Card
      marginX="auto"
      maxWidth="35rem"
      variant="outline"
      padding="40"
      display="flex"
      flexDirection="column"
      {...cardProps}
    >
      {header}
      <Input
        size="lg"
        type="email"
        label="Email"
        value={email}
        errorText={emailError ? 'Not valid email' : undefined}
        placeholder="Enter your work email"
        isRequired
        onChange={(ev) => {
          setEmailError(Boolean(ev.target.value) && !ev.target.validity.valid);
          setEmail(ev.target.value);
        }}
      />
      {requireConsent && (
        <TermsOfServiceAndPrivacyPolicy checked={consent} onChange={(ev) => setConsent(ev.target.checked)} />
      )}
      <Button
        isLoading={isPending}
        size="lg"
        onClick={() => mutate({ email })}
        isDisabled={!email || emailError || needsConsent}
      >
        Continue with SSO
      </Button>
      <Button variant="tertiary" size="md" alignSelf="start" onClick={onBack}>
        {backLabel}
      </Button>
    </Card>
  ) : (
    <Box marginX="auto" maxWidth="35rem" display="flex" flexDirection="column" gap="40">
      <div>
        <Text textStyle="heading/h1" marginBottom="16">
          Check your inbox!
        </Text>
        <Text>
          We've sent a secure sign-in link to your email address,{' '}
          <Text as="span" textStyle="body/lg/semibold">
            {email}
          </Text>
          .
        </Text>
        <Text>Please check your inbox (and your spam folder just in case).</Text>
      </div>
      <div>
        <Text textStyle="heading/h1" marginBottom="16">
          Didn't get the email?
        </Text>
        <Text>
          If you didn’t receive the email, you can request another magic link or reach out to our{' '}
          <Link colorScheme="purple" isExternal href="https://bitrise.io/contact">
            support team
          </Link>{' '}
          for assistance.
        </Text>
      </div>
      {state === 'resent' ? (
        <Notification status="success">Please check your inbox.</Notification>
      ) : (
        <Button size="md" variant="secondary" onClick={() => mutate({ email })} alignSelf="start">
          Resend email
        </Button>
      )}
    </Box>
  );
};

export default SSOEmailForm;
